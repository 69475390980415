<template>
    <div class="login_panel-wrapper">
        <div class="auth-wrapper">
            <img class="hmi-logo" src="@/assets/hmi_logo_darkmode.png" alt="">

            <form @keydown.enter="signIn()" class="login-wrapper" v-if="!forgot_password_action">
                <h1 class="login-label"> Sign in <br> to continue </h1>
                    <InputText  placeholder="Enter email address" class="login-input" v-model="login" />
                    <InputText type="password" placeholder="Enter password" class="login-input"  v-model="password" />
                    <Button :loading="login_loading" @click="signIn()" label="Sign in" icon="pi pi-sign-in" iconPos="left" class="sign-in-button pi-button pi-button-info" />
                    <div class="error-message" v-if="error_message">
                        {{ error_message }}
                    </div>
                    <div class="forgot-password-wrapper">
                        <span @click="forgot_password_action=true"> First Login | Forgot password </span>
                    </div>

            </form>


            <form  class="login-wrapper" v-else>
                <h1 class="login-label"> Password restart or first setup </h1>
                    <InputText  placeholder="Enter your email address" class="login-input" v-model="password_recovery_email" />
                    <Button :disabled="reset_counter" :loading="reseting_loading" @click="sendRestartPasswordEmail()" :label="!reset_counter ? 'Send email' : `Email has been sent (${reset_counter}s)`" :icon="!reset_counter ? 'pi pi-refresh' : 'pi pi-check'" iconPos="left" class="sign-in-button pi-button pi-button-warning" />
                <div class="error-message" v-if="error_message">
                    {{ error_message }}
                </div>
                <div class="forgot-password-wrapper">
                    <span @click="forgot_password_action=false"> Go back </span>
                </div>
            </form>
            <div class="auth-footer">
                <h2> Your account expired?</h2>
                <Button @click="contactUsRedirect()"  label="Contact us" icon="pi pi-comments" iconPos="right" class="contact-us-button p-button p-button-info p-button-text p-button-rounded" />
            </div>
            <div class="privacy-wrapper">
                <div class="policy-row">
                    <a href="https://support.easygen.org/en-US/guides/simulation-in-the-cloud/0-introduction#0-introduction_cloud-simulation---quick-start-guide" target="_blank"> Help </a>
                    <a href="/privacy" target="_blank"> Privacy Policy </a>
                    <!-- <a href="https://www.woodward.com/en/terms-and-conditions" target="_blank"> Terms & Conditions </a> -->
                    <a href="" @click.prevent="$CookieConsent.show(true)"> Cookies </a>
                </div>
                <span>
                    Copyright © 2024 Woodward, Inc.
                </span>
            </div>
        </div>


        <div class="photo-wrapper" />
    </div>
</template>

<script>
    import InputText from 'primevue/inputtext';
    import Button from 'primevue/button';
    export default {
        components: {
            InputText,
            Button
        },
        data() { 
            return {
                login: null,
                password: null,
                login_loading: false,
                error_message: null,
                forgot_password_action: false,
                password_recovery_email: null,
                reseting_loading: false,
                reset_counter: null

            }   
        },
        methods: {
            async signIn() {
                this.login_loading = true
                try {
                    await this.$store.dispatch('logIn', {email: this.login, password: this.password})
                    const initialPasswordChanged = this.getInitialPasswordChangedData()
                    if(initialPasswordChanged) {
                        this.$router.replace('/home/settings') 
                    } else {
                        this.$router.replace('/home/settings') 
                    }
                    this.error_message = null
                } catch (err) {
                    console.log(err.code)
                    switch (err.code) {
                        case 'auth/user-not-found':
                            this.error_message = 'User not found. Please double-check that you have entered the correct email address and try again.'
                            break;
                        case 'auth/wrong-password':
                            this.error_message = 'Wrong password. Please try entering your password again or use the password recovery option to reset your password.'
                            break;
                        case 'auth/user-disabled':
                            this.error_message = 'Account expired. Please contact us.'
                            break
                        case 'auth/network-request-failed':
                            this.error_message = 'Network failed. Check you internet connection.'
                            break;
                        case 'auth/invalid-email':
                            this.error_message = 'Wrong email. The email entered is not an email.'
                            break;
                    }
                } finally {
                    this.login_loading = false
                }
            },
            async getInitialPasswordChangedData() {
                const initialPasswordChanged = await this.$store.dispatch('fetchInitialPasswordChanged')
                this.$store.commit('setInitialPasswordChanged', {initialPasswordChanged: initialPasswordChanged})
                return initialPasswordChanged
            },
            async sendRestartPasswordEmail() {
                this.reseting_loading = true
                try {
                    await this.$store.dispatch('sendRestartPasswordEmail', {email: this.password_recovery_email})
                    this.reset_counter = 60
                    let resetInterval = setInterval(() => {
                        this.reset_counter--
                        if (this.reset_counter === 0) {
                            clearInterval(resetInterval)
                            this.reset_counter = null
                        }
                    }, 1000);
                    this.error_message = null
                } catch(err) {
                    console.log(err.code)
                    switch (err.code) {
                        case 'auth/user-not-found':
                            this.error_message = 'User not found. Please double-check that you have entered the correct email address and try again.'
                            break;
                        case 'auth/network-request-failed':
                            this.error_message = 'Network failed. Check you internet connection.'
                            break;
                        case 'auth/invalid-email':
                            this.error_message = 'Wrong email. The email entered is not an email.'
                            break;
                        case 'auth/user-disabled':
                            this.error_message = 'Account expired. Please contact us.'
                            break
                        default:
                             this.error_message = 'Something went wrong, please try again later'
                            break
                    }
                } finally {
                    this.reseting_loading = false
                }
            },
            contactUsRedirect() {
                window.open(
                    'https://support.easygen.org/en-US/new-ticket',
                    '_blank'
                )
            }
        },
        watch: {
            forgot_password_action() {
                this.error_message = null
            }
        }
    }
</script>

<style scoped>
.login_panel-wrapper {
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: #282936;
}
.auth-wrapper {
    width: 40%;
    padding: 1rem 4rem;
    display: flex;
    min-width: 28rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2.5rem;
    overflow: auto; 
    /* background-color: aqua; */
}
.photo-wrapper {
    display: flex;
    flex: 1;
    background-image: url("../assets/login_landing2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}
.hmi-logo {
    width: 14rem;
}
.login-label {
    color: #eaeaea;
    font-size: 2.1rem;
    letter-spacing: 1.5px;

}
.login-wrapper {
    display: flex;
    /* margin-top: 4rem; */
    flex-direction: column;
    width: 100%;
    gap: 1rem;

}
.login-input {
    background-color: transparent !important;
    border: 1px solid #b4b5b8 !important;
    border-radius: 6px;
    color: #d1d1d1 !important;
}
.login-input:hover {
    border: 1px solid #a6a6a7 !important;
}
.auth-footer {
    /* margin-top: 10rem; */
    color: #dfdfdf;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.sign-in-button {
    background-color: #93C5FD;
    border-color: #93C5FD;
    color: #282936;
}
.sign-in-button:hover {
    background-color: #8db7e6;
    border-color: #8db7e6;
    color: #282936;
}
.contact-us-button {
    border-color: var(--font3) !important;
    color: var(--font1) !important;
}
.forgot-password-wrapper {
    display: flex;
    justify-content: flex-end;
    color: var(--font1);
    font-size: small;
    text-decoration: underline;
}
.forgot-password-wrapper:hover {
    cursor: pointer;
    color: var(--font3);
}
.error-message {
    color: #F19EA6;
}
@media (max-width: 1000px) {
    .photo-wrapper {
        display: none;
    }
    .auth-wrapper {
        width: 100%;
    }
}
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
    -webkit-box-shadow: unset !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    color: #282936 !important;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #530f0f29;
    -webkit-text-fill-color: #d1d1d1 !important;
    caret-color: #d1d1d1;
}

.or-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--font1);
    flex-direction: column;
}
.first-login-button {
    color: #93C5FD;
}
.privacy-wrapper {
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 0.1rem;
}
.policy-row {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}
.policy-row > * {
    font-size: 0.9rem;
    color: rgb(212, 212, 212) !important; 

}
.privacy-wrapper > span {
    display: flex;
    justify-content: center;
    margin-top: 0.3rem;
    font-size: 0.9rem;
    color: rgb(187, 187, 187) !important;
}

</style>